<template>
  <v-layout v-if="tablePagination" row wrap class="flex-none px-1" justify-end>
    <div class="d-flex mx-2 caption"><span style="margin: auto;">{{$t('pagination.items_per_page')}}</span></div>
    <v-select
      hide-details
      style="flex: 0 1 0;"
      class="pt-0 mx-5 my-3 caption"
      :items="tablePagination.itemsPerPageOptions || pagination.itemsPerPageOptions"
      v-model="tablePagination.pages.itemsPerPage"
    />
    <div class="d-flex mx-2 caption">
      <span style="margin: auto;">
        {{$t('pagination.page_text', {
          0: firstItem,
          1: lastItem,
          2: totalItems })
          }}
      </span>
    </div>
    <v-btn icon large @click="$store.commit('pagination/decrease', { table })" :disabled="tablePagination.loading || tablePagination.pages.page === 1">
      <v-icon size="25">mdi-chevron-left</v-icon>
    </v-btn>
    <v-btn icon large @click="$store.commit('pagination/increase', { table })" :disabled="tablePagination.loading || !tablePagination.pages.hasNext">
      <v-icon size="25">mdi-chevron-right</v-icon>
    </v-btn>
  </v-layout>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    table: String,
    external: Boolean
  },
  computed: {
    ...mapState(['pagination']),
    tablePagination () {
      return this.pagination.tables[this.table]
    },
    firstItem () {
      return (this.tablePagination.pages.page - 1) * this.tablePagination.pages.itemsPerPage +
        1 * (
          (this.tablePagination.pages.page === 1 && this.tablePagination.pages.reachedEnd && this.tablePagination.pages.totalItems === 0)
            ? 0
            : 1)
    },
    lastItem () {
      return !this.tablePagination.pages.hasNext
        ? ((this.tablePagination.pages.page - 1) * this.tablePagination.pages.itemsPerPage + this.tablePagination.pages.totalItems)
        : (this.tablePagination.pages.page * this.tablePagination.pages.itemsPerPage)
    },
    totalItems () {
      return this.tablePagination.pages.reachedEnd
        ? ((this.tablePagination.pages.totalPages - 1) * this.tablePagination.pages.itemsPerPage + this.tablePagination.pages.totalItems)
        : this.$t('pagination.many')
    }
  },
  watch: {
    'tablePagination.pages.page' () {
      this.$store.dispatch('pagination/listRequest', { table: this.table })
    },
    'tablePagination.pages.itemsPerPage' () {
      this.$store.dispatch('pagination/filterListRequest', { table: this.table })
    },
    'tablePagination.pages.descending' () {
      this.$store.dispatch('pagination/filterListRequest', { table: this.table })
    }
  }
}
</script>

<style lang="scss" scoped>
.flex-none {
  margin: 0 !important;
  border-top: thin solid rgba(0, 0, 0, 0.12);
}
</style>
